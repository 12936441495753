$('.language-overlay').on('click', '#hide-language-overlay', function () {
    $.ajax({
        url: "/api/sitecore/navigation/hidelanguageoverlay",
        type: "POST",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        cache: false,
        success: function (results) {
            $('.language-overlay').hide();
            $('.language-background-cover').hide();
        },
        error: function (e) {
            console.log('Error contacting server. ' + e);
        }
    });
});

$(".language-background-cover, .stay-in-current-language, .language-overlay-close").click(function (e) {
    e.preventDefault();
    $('.language-overlay').hide();
    $('.language-background-cover').hide();
});
